import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`Oopsies!!! That page doesn't exist.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "/"
        }}>{`Let's go home`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
    